.header {
    /* background-color: #a9b0be; */
    /* min-height: 40px; */
}
.App-header {
    /* background-color: #a9b0be; */
    /* min-height: 40px; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: #282c34;
}

.App-link {
    color: #61dafb;
}
